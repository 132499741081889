<!-- 统计表 -->
<template>
    <div class="CensusTable">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">检索学员出勤统计表统计表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="150px">
            <el-form-item label="出勤人次数">
                <el-input :value="form.person" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="请假人数">
                <el-input :value="form.leaveNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="综合出勤率">
                <el-input :value="form.multiple" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="记录数">
                <el-input :value="form.recordNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="班级">
                <el-input class="text-ipt" :value="form.class" placeholder="" disabled></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   person:"1",//出勤人次数 
                   leaveNum:"",//请假人数
                   multiple:"",//综合出勤率
                   recordNum:"",//记录数
                   class:""//班级
                }
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped="scoped">
    .CensusTable{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    .el-input {
        width: 300px;
        height: 37px;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #333;
    }
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
</style>
